import React from "react"
import Layout from "../../components/layout"
import Directory from "../../components/directory/directory"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const RenderBody = ({ kidsArt, meta }) => (
  <>
    <Helmet
      title={meta.title}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <div className="w-full py-24 flex justify-center items-center">
      <div className="w-4/5 flex flex-row flex-wrap">
        <div classname="w-full">
          <h1>Kids Art Submissions</h1>
        </div>
        <div className="w-full grid grid-cols-1 desktop:grid-cols-3 gap-6">
          {kidsArt.map((art, i) => (
            <GatsbyImage
              image={art.node.image.localFile.childImageSharp.gatsbyImageData}
              key={i}
              className="min-h-full w-full shadow-md shadow-black/50"
              alt={art.node.title}
            />
          ))}
        </div>
      </div>
    </div>
  </>
)

const KidsArt = ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata
  const kidsArt = data.allGraphCmsKidsArtSubmission.edges

  if (!kidsArt) return null

  return (
    <Layout>
      <RenderBody kidsArt={kidsArt} meta={meta} />
      <Directory />
    </Layout>
  )
}

export default KidsArt

RenderBody.propTypes = {
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query KidsArt {
    allGraphCmsKidsArtSubmission {
      edges {
        node {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  blurredOptions: { toFormat: AUTO }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
